import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "leads nav-link alert bg-light-danger align-items-center leads-btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["d-flex search-block lead-filter-buttons mb-5", {'justify-content-end': !_ctx.textMessage}]),
    style: _normalizeStyle(_ctx.textMessage ? 'justify-content: space-between' : '')
  }, [
    (_ctx.textMessage)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.textMessage), 1))
      : _createCommentVNode("", true),
    _createElementVNode("a", _hoisted_2, _toDisplayString(_ctx.total) + " Record Count ", 1)
  ], 6))
}