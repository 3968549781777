
import { defineComponent } from "vue";

export default defineComponent({
  name: "record-selecteds",
  props: {
    total: {
        type:Number,
        default:0
    },
    textMessage: {
        type: String,
        required: false,
        default: null
    }

    
  }
   
});
